import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const ComponentTitle = ({ title, highlight }) => {
  const [isVisible, setIsVisible] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <h2
      ref={titleRef}
      className={`text-4xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-semibold font-passionOne text-center transition-all duration-1000 ${
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      {title}
      <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
        {highlight}
      </span>
    </h2>
  );
};

ComponentTitle.propTypes = {
  title: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
};

export default ComponentTitle;
