import React from "react";
import ComponentTitle from "./ComponentTitle";

const About = () => {
  return (
    <section id="about" className="py-16 bg-gray-100 text-gray-800">
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="A" highlight=" Propos" />
        </div>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 mb-8 md:mb-0">
            <img
              src="/images/LV.webp"
              alt="Victorin Laurent"
              className="rounded-full mx-auto h-48 w-48 object-cover"
            />
          </div>
          <div className="md:w-2/3 md:pl-12 text-center md:text-left">
            <h3 className="text-2xl font-semibold mb-4">Laurent Victorin</h3>
            <p className="text-lg">
              Développeur Web & Web Mobile, Président de club de Judo,
              Professeur, et Membre du Comité Départemental.
            </p>
            <p className="mt-4">
              Passionné par le développement web et le judo, j'ai combiné ces deux passions pour créer une solution de gestion de club qui répond aux besoins spécifiques des clubs. Mon expérience en tant que dirigeant et enseignant de judo m'a permis de comprendre les défis que rencontrent les clubs, et j'ai conçu <b>MonAppliClub</b> pour y répondre efficacement.
            </p>
            <div className="mt-8">
              <a
                href="https://www.laurent-victorin.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="px-8 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-full transition"
              >
                Mon Portfolio
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
