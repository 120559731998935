import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoIosMenu, IoIosClose } from 'react-icons/io';
import { FaSignOutAlt } from 'react-icons/fa';  // Import de l'icône de déconnexion
import './LinkNav.css';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState({
    photoURL: '',
    nom: '',
    prenom: '',
    role_id: null
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Vérifier si l'utilisateur est connecté et récupérer ses informations
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('userInfo'));
    if (token && user) {
      setIsAuthenticated(true);
      setUserInfo({
        photoURL: user.photoURL || '/img/user_avatar.jpg',
        nom: user.nom,
        prenom: user.prenom,
        role_id: user.role_id,
      });
    }

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('userInfo'));
      if (token && user) {
        setIsAuthenticated(true);
        setUserInfo({
          photoURL: user.photoURL || '/img/user_avatar.jpg',
          nom: user.nom,
          prenom: user.prenom,
          role_id: user.role_id,
        });
      } else {
        setIsAuthenticated(false);
        setUserInfo({
          photoURL: '',
          nom: '',
          prenom: '',
          role_id: null,
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setIsOpen(false);
  };

  const handleHomeClick = () => {
    navigate('/');  // Redirige vers la page d'accueil
    window.scrollTo({ top: 0, behavior: 'smooth' });  // Remonte en haut de la page
    setIsOpen(false);
  };

  const handleLogout = () => {
    // Logique pour se déconnecter
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    setIsAuthenticated(false);
    setUserInfo({
      photoURL: '',
      nom: '',
      prenom: '',
      role_id: null,
    });
    navigate('/'); // Redirige vers la page d'accueil
    window.location.reload(); // Force le rechargement de la page
  };

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        scrolled ? 'bg-gradient-to-b from-neutral-800 to-neutral-500' : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="text-4xl font-semibold font-passionOne">
          <button onClick={handleHomeClick} className="text-white focus:outline-none">
            Mon
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
              Appli
            </span>
            Club
          </button>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMenu} aria-label="Menu" className="text-white focus:outline-none">
            {isOpen ? (
              <IoIosClose className="text-3xl transition-transform duration-300 transform rotate-180" />
            ) : (
              <IoIosMenu className="text-3xl transition-transform duration-300" />
            )}
          </button>
        </div>
        <ul className={`hidden lg:flex space-x-8 text-white items-center`}>
          <li>
            <button
              onClick={() => handleNavigate("about")}
              className="hover:text-gray-300 transition link-nav2"
            >
              À Propos
            </button>
          </li>
          <li>
            <button
              onClick={() => handleNavigate("diaporama")}
              className="hover:text-gray-300 transition link-nav2"
            >
              Diaporama
            </button>
          </li>
          <li>
            <button
              onClick={() => handleNavigate("features")}
              className="hover:text-gray-300 transition link-nav2"
            >
              Fonctionnalités
            </button>
          </li>
          <li>
            <button
              onClick={() => handleNavigate("disciplines")}
              className="hover:text-gray-300 transition link-nav2"
            >
              Disciplines
            </button>
          </li>
          <li>
            <button
              onClick={() => handleNavigate("contact")}
              className="hover:text-gray-300 transition link-nav2"
            >
              Contact
            </button>
          </li>

          {isAuthenticated && (
            <>
              <li>
                <NavLink
                  to="/appli"
                  className="px-4 py-2 rounded-full transition bg-gradient-to-r from-yellow-400 to-orange-600 text-black font-lato text-base hover:from-yellow-500 hover:to-orange-700 whitespace-nowrap"
                >
                  Espace Client
                </NavLink>
              </li>
              <li className="flex items-center space-x-4">
                <img
                  src={userInfo.photoURL}
                  alt="Avatar"
                  className="h-8 w-8 rounded-full object-cover"
                />
                <span className="text-sm">
                  {userInfo.nom} {userInfo.prenom}
                </span>
                <FaSignOutAlt
                  className="text-xl cursor-pointer hover:text-orange-400 transition"
                  onClick={handleLogout}
                  title="Se déconnecter"
                />
              </li>
            </>
          )}
        </ul>
      </div>

      {isOpen && (
        <div className="lg:hidden bg-neutral-800 text-white w-full px-4 py-4 absolute top-full right-0 z-50">
          <ul className="flex flex-col space-y-4">
            <li>
              <button
                onClick={() => handleNavigate("about")}
                className="hover:text-gray-300 transition text-left link-nav2"
              >
                À Propos
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavigate("diaporama")}
                className="hover:text-gray-300 transition text-left link-nav2"
              >
                Diaporama
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavigate("features")}
                className="hover:text-gray-300 transition text-left link-nav2"
              >
                Fonctionnalités
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavigate("disciplines")}
                className="hover:text-gray-300 transition text-left link-nav2"
              >
                Disciplines
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavigate("contact")}
                className="hover:text-gray-300 transition text-left link-nav2"
              >
                Contact
              </button>
            </li>
            {isAuthenticated && (
              <>
                <li>
                <NavLink
                  to="/appli"
                  className="px-4 py-2 rounded-full transition bg-gradient-to-r from-yellow-400 to-orange-600 text-black font-lato text-base hover:from-yellow-500 hover:to-orange-700"
                >
                  Espace Client
                </NavLink>
              </li>
                <li className="flex items-center space-x-4">
                  <img
                    src={userInfo.photoURL}
                    alt="Avatar"
                    className="h-8 w-8 rounded-full object-cover"
                  />
                  <span className="text-sm">
                    {userInfo.nom} {userInfo.prenom}
                  </span>
                  <FaSignOutAlt
                    className="text-xl cursor-pointer hover:text-orange-400 transition"
                    onClick={handleLogout}
                    title="Se déconnecter"
                  />
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
