import React, { useEffect, useState } from "react";
import ComponentTitle from "./ComponentTitle";
import { getAllProducts } from "../../services/productsServices";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Pricing() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsData = await getAllProducts();
        // Filtrer les produits pour exclure celui avec l'ID 6
        const filteredProducts = productsData.filter(product => product.id !== 6);
        setProducts(filteredProducts);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des produits:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div>Chargement des tarifs...</div>;
  }

  // Regrouper les produits par paires pour les afficher deux par deux
  const productPairs = [];
  for (let i = 0; i < products.length; i += 2) {
    productPairs.push(products.slice(i, i + 2));
  }

  return (
    <section className="py-4 bg-base-100 text-gray-800">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Nos" highlight=" Tarifs" />
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Choisissez le plan qui vous convient le mieux.
          </p>
        </div>

        <Carousel
          autoPlay
          infiniteLoop
          useKeyboardArrows
          showThumbs={false}
          showStatus={false}
          centerMode={false}
          showIndicators={true}
          swipeable={true}
          dynamicHeight={false}
          emulateTouch={true}
          showArrows={true}
          interval={7000} // Vitesse de défilement ralentie à 7 secondes
        >
          {productPairs.map((pair, index) => (
            <div key={index} className="flex justify-center gap-6">
              {pair.map((product) => (
                <div
                  key={product.id}
                  className="no-select flex flex-col p-6 text-center text-gray-900 bg-slate-200 rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
                  style={{ width: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} // Largeur fixe pour chaque carte
                >
                  <div>
                    <h3 className="mb-4 text-2xl font-semibold">{product.nom_product}</h3>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">{product.tarif_product}€</span>
                      <span className="text-gray-500 dark:text-gray-400">/{product.periodicite_product}</span>
                    </div>
                    <div className="mb-8 space-y-4 text-left">
                      <div dangerouslySetInnerHTML={{ __html: product.contenu_product }} />
                    </div>
                  </div>
                  <div className="mt-auto">
                    <a href="#" className="px-6 py-2 rounded-full shadow-lg text-lg font-semibold transition-all duration-300 bg-gradient-to-br from-blue-600 to-blue-400 text-white hover:from-blue-500 hover:to-blue-300">
                      {product.categorie_product === "Abonnement" ? "S'abonner" : "Choisir"}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}
