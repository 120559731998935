import React, { useState, useEffect } from "react";
import useScrollToTop from "../../hooks/useScrollToTop";
import { getSettings } from "../../services/settingsServices";

const PolitiqueConfidentialitePageScoreboard = () => {
  // Scroll to top screen
  useScrollToTop();

  const [settings, setSettings] = useState({
    siteweb_club: "",
    nom_club: "",
    adresse_club: "",
    responsable_club: "",
    mail_club: "",
    siret_club: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
      }
    };

    fetchSettings();
  }, []);

  return (
    <div>
      <div>
        <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[80px] w-full"></div>
        <div className="flex flex-col w-full max-w-[1280px] mx-auto p-6 px-10 bg-gray-200 dark:bg-backgroundMainDark dark:text-white ">
          <h1 className="text-xl tracking-widest text-gray-800 text-center dark:text-white">
            Textes Réglementaires
          </h1>
          <h2 className="text-4xl font-bold uppercase text-center my-6 mb-10">
            Politique de Confidentialité
          </h2>

          {/* Content Section */}
          <div className="flex flex-col space-y-4">
            <h3 className="text-2xl font-bold">1. Introduction</h3>
            <p>
              Chez <b>le développeur indépendant de l'application, VICTORIN Laurent</b>, nous prenons très au sérieux la
              confidentialité de nos utilisateurs. <b>JudoScore Pro</b> est une application dédiée à la gestion des scores lors de compétitions de
              judo, permettant de marquer les points, gérer les chronomètres, et
              définir le vainqueur. Cette politique de confidentialité explique
              comment nous gérons les données des utilisateurs et comment nous nous
              engageons à protéger leur vie privée.
            </p>

            <h3 className="text-2xl font-bold">
              2. Aucune Collecte de Données Personnelles
            </h3>
            <p>
              L'application <b>JudoScore Pro</b> ne collecte, n'enregistre ni
              n'utilise aucune donnée personnelle ou sensible de ses
              utilisateurs. Aucun compte utilisateur, mot de passe ou
              information personnelle n'est requis pour l'utilisation de
              l'application.
            </p>

            <h3 className="text-2xl font-bold">3. Utilisation de l'Application</h3>
            <p>
              <b>JudoScore Pro</b> permet de :
              <ul className="list-disc ml-6">
                <li>Marquer des points et suivre un combat de judo.</li>
                <li>Utiliser différentes options d'affichage.</li>
                <li>
                  Paramétrer des aspects comme le temps de combat, le son pour le
                  gong et la langue.
                </li>
              </ul>
            </p>

            <h3 className="text-2xl font-bold">4. Cookies et Technologies Similaires</h3>
            <p>
              L'application <b>JudoScore Pro</b> n'utilise pas de cookies ou
              d'autres technologies pour collecter des informations sur les
              utilisateurs.
            </p>

            <h3 className="text-2xl font-bold">5. Sécurité des Données</h3>
            <p>
              Même si l'application <b>JudoScore Pro</b> ne collecte pas de
              données personnelles, nous nous engageons à maintenir la sécurité
              de l'application pour assurer une expérience sûre et fiable.
            </p>

            <h3 className="text-2xl font-bold">6. Liens Externes</h3>
            <p>
              L'application inclut une section "Informations" expliquant en détail les fonctionnalités de l'application et contient un lien
              permettant aux utilisateurs de faire une participation de 5€ s'ils le souhaitent au développeur.
              Non obligatoire, cette participation est facultative et n'implique
              pas de collecte de données personnelles.
            </p>

            <h3 className="text-2xl font-bold">7. Modifications de la Politique de Confidentialité</h3>
            <p>
              Nous nous réservons le droit de modifier cette politique de
              confidentialité à tout moment. Toute modification sera publiée
              directement dans l'application.
            </p>

            <h3 className="text-2xl font-bold">8. Contact</h3>
            <p>
              Pour toute question relative à cette politique de confidentialité,
              veuillez nous contacter à l'adresse suivante :{" "}
              <b>victorinlaurent33@gmail.com</b>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolitiqueConfidentialitePageScoreboard;
