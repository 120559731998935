import React, { useState, useEffect } from "react";
import { FaUsers, FaHandshake, FaTasks, FaStar  } from "react-icons/fa";
import { RiNumbersFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { countUsers } from "../../../../services/userServices";
import {
  countAssociations,
  countClientAssociations,
} from "../../../../services/associationsServices";
import { countTasksNotDone } from "../../../../services/tasksServices";
import { countVisites } from "../../../../services/visitesServices";

const StatisticsCards = () => {
  const [usersCount, setUsersCount] = useState(0);
  const [associationsCount, setAssociationsCount] = useState(0);
  const [clientAssociationsCount, setClientAssociationsCount] = useState(0);
  const [tasksNotDoneCount, setTasksNotDoneCount] = useState(0);
  const [visitesCount, setVisitesCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Récupération du nombre d'utilisateurs
        const usersResponse = await countUsers();
        setUsersCount(usersResponse.count);

        // Récupération du nombre d'associations
        const associationsResponse = await countAssociations();
        setAssociationsCount(associationsResponse.count);

        // Récupération du nombre d'associations client
        const clientAssociationsResponse = await countClientAssociations();
        setClientAssociationsCount(clientAssociationsResponse.count);

        // Récupération du nombre de tâches non terminées
        const tasksNotDoneResponse = await countTasksNotDone();
        setTasksNotDoneCount(tasksNotDoneResponse.count);
      } catch (error) {
        console.error("Erreur lors de la récupération des statistiques", error);
      }

      // Récupération du nombre de visites
      const visitesResponse = await countVisites();
      setVisitesCount(visitesResponse.count);
    };

    fetchCounts();
  }, []);

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
      <div
        className="flex p-3 bg-gray-800 rounded-lg shadow-lg flex-col items-center space-y-1 min-w-[150px] w-full md:w-auto dark:bg-gray-900 dark:text-gray-200 cursor-pointer hover:bg-gray-700"
        onClick={() => handleCardClick("/appli/listing-users")}
      >
        <FaUsers className="text-white text-3xl" />
        <p className="text-white text-2xl font-bold">{usersCount}</p>
        <p className="text-gray-400 text-sm">Utilisateurs</p>
      </div>

      <div
        className="flex p-3 bg-gray-800 rounded-lg shadow-lg flex-col items-center space-y-1 min-w-[150px] w-full md:w-auto dark:bg-gray-900 dark:text-gray-200 cursor-pointer hover:bg-gray-700"
        onClick={() => handleCardClick("/appli/listing-associations")}
      >
        <FaStar  className="text-white text-3xl" />
        <p className="text-white text-2xl font-bold">{associationsCount}</p>
        <p className="text-gray-400 text-sm">Associations/Clubs</p>
      </div>

      <div
        className="flex p-3 bg-gray-800 rounded-lg shadow-lg flex-col items-center space-y-1 min-w-[150px] w-full md:w-auto dark:bg-gray-900 dark:text-gray-200 cursor-pointer hover:bg-gray-700"
        onClick={() => handleCardClick("/appli/listing-associations")}
      >
        <FaHandshake className="text-white text-3xl" />
        <p className="text-white text-2xl font-bold">
          {clientAssociationsCount}
        </p>
        <p className="text-gray-400 text-sm">Associations Client</p>
      </div>

      <div
        className="flex p-3 bg-gray-800 rounded-lg shadow-lg flex-col items-center space-y-1 min-w-[150px] w-full md:w-auto dark:bg-gray-900 dark:text-gray-200 cursor-pointer hover:bg-gray-700"
        onClick={() => handleCardClick("/appli/liste-taches")}
      >
        <FaTasks className="text-white text-3xl" />
        <p className="text-white text-2xl font-bold">{tasksNotDoneCount}</p>
        <p className="text-gray-400 text-sm">Tâches Non Terminées</p>
      </div>

      <div className="flex p-3 bg-gray-800 rounded-lg shadow-lg flex-col items-center space-y-1 min-w-[150px] w-full md:w-auto dark:bg-gray-900 dark:text-gray-200 cursor-pointer hover:bg-gray-700">
        <RiNumbersFill  className="text-white text-3xl" />
        <p className="text-white text-2xl font-bold">{visitesCount}</p>
        <p className="text-gray-400 text-sm">Visites</p>
      </div>
    </div>
  );
};

export default StatisticsCards;
