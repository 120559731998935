import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import Button from "./../../../../components/Button/Button";
import ButtonEdit from "./../../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../../components/Button/ButtonDelete";
import { useUser } from "./../../../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../../../utils/roleHelper";
import {
  getRoles,
  getAllUsers,
  deleteUser as deleteUserAPI,
} from "./../../../../services/userServices";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal";
import ManageAssociationsModal from "./ManageAssociationsModal";

const ListingUsers = () => {
  useScrollToTop();
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showManageAssociationsModal, setShowManageAssociationsModal] = useState(false);
  const [editUserId, setEditUserId] = useState(null);

  const fetchData = async () => {
    try {
      const usersData = await getAllUsers();
      const rolesData = await getRoles();
      const rolesMap = rolesData.reduce((acc, role) => {
        acc[role.id] = role.nom;
        return acc;
      }, {});
      setUsers(usersData);
      setDisplayedUsers(usersData);
      setRoles(rolesMap);
    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      toast.error("Erreur lors du chargement des données");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterUsers(searchTermLocal, selectedRole);
  };

  const handleRoleChange = (e) => {
    const selectedRoleLocal = e.target.value;
    setSelectedRole(selectedRoleLocal);
    filterUsers(searchTerm, selectedRoleLocal);
  };

  const filterUsers = (term, role) => {
    const filteredUsers = users.filter((user) => {
      const matchesSearchTerm =
        user.nom.toLowerCase().includes(term.toLowerCase()) ||
        user.prenom.toLowerCase().includes(term.toLowerCase());

      const matchesRole = role ? user.role_id === parseInt(role) : true;

      return matchesSearchTerm && matchesRole;
    });
    setDisplayedUsers(filteredUsers);
  };

  const deleteUser = async (userId) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer cet utilisateur ? ID: ${userId}`)) {
      try {
        const message = await deleteUserAPI(userId);
        toast.success(message);
        setUsers(users.filter((user) => user.id !== userId));
        fetchData();
      } catch (error) {
        console.error("Erreur lors de la suppression de l'utilisateur:", error);
        toast.error("Erreur lors de la suppression de l'utilisateur");
      }
    }
  };

  const getRoleDisplay = (roleId) => {
    switch (roleId) {
      case 1:
        return { name: "Administrateur", className: "bg-red-500 text-white" };
      case 2:
        return { name: "Utilisateur", className: "bg-blue-500 text-white" };
      case 3:
        return { name: "Client", className: "bg-green-500 text-white" };
      default:
        return { name: "Rôle inconnu", className: "bg-gray-500 text-white" };
    }
  };

  const handleAddUser = () => {
    setShowAddModal(true);
  };

  const openEditModal = (userId) => {
    setEditUserId(userId);
    setShowEditModal(true);
  };

  const openManageAssociationsModal = (userId) => {
    setEditUserId(userId);
    setShowManageAssociationsModal(true);
  };

  const closeAddUserModal = () => {
    setShowAddModal(false);
  };

  const closeEditUserModal = () => {
    setShowEditModal(false);
  };

  const closeManageAssociationsModal = () => {
    setShowManageAssociationsModal(false);
  };

  const refreshUserList = () => {
    fetchData();
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-light tracking-wide text-gray-800 dark:text-white mb-4">
        Listing Utilisateurs
      </h2>

      <div className="w-full mx-auto mb-4">
        <Button
          text="Ajouter Utilisateur"
          onClick={handleAddUser}
          icon={FaPlus}
          className="w-full gap-2 mb-4"
        />

        <div className="flex flex-col md:flex-row justify-between mb-2 gap-2">
          <div className="w-full md:w-1/3">
            <select
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <option value="">Tous les rôles</option>
              <option value="1">Administrateur</option>
              <option value="2">Utilisateur</option>
              <option value="3">Client</option>
            </select>
          </div>

          <div className="w-full md:w-1/3">
            <input
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {/* Table for desktop, hidden on mobile */}
        <div className="hidden md:block overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
          <table className="w-full text-left text-sm text-gray-400">
            <thead className=" border-b border-gray-700">
              <tr>
                <th className="pb-3 px-4 text-gray-400">Photo</th>
                <th className="pb-3 px-4 text-gray-400">Nom Prénom</th>
                <th className="pb-3 px-4 text-gray-400">Email</th>
                <th className="pb-3 px-4 text-gray-400">Rôle</th>
                <th className="pb-3 px-4 text-gray-400">Associations</th>
                <th className="pb-3 px-4 text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedUsers.map((user) => (
                <tr key={user.user_id} className="border-b border-gray-700">
                  <td className="py-2 px-4 text-white">
                    <img
                      className="rounded-full w-10 h-10 object-cover"
                      src={user.photoURL || "/img/user_avatar.jpg"}
                      alt="Avatar"
                    />
                  </td>
                  <td className="py-2 px-4 text-white">
                    {user.nom} {user.prenom}
                  </td>
                  <td className="py-2 px-4 text-gray-400">{user.email}</td>
                  <td className="py-2 px-4">
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${getRoleDisplay(user.role_id).className}`}
                    >
                      {getRoleDisplay(user.role_id).name}
                    </span>
                  </td>
                  <td className="py-2 px-4 text-gray-400">
                    {user.Associations && user.Associations.length > 0 ? (
                      user.Associations.map((association) => (
                        <div key={association.id}>{association.nom_asso}</div>
                      ))
                    ) : (
                      <div>Aucune association</div>
                    )}
                  </td>
                  <td className="py-2 px-4 flex gap-2">
                    <ButtonEdit
                      onClick={() => openEditModal(user.id)}
                      hoverLabel="Éditer"
                    />
                    <button
                      className="w-8 h-8 bg-yellow-500 text-white rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-yellow-700 flex items-center justify-center"
                      title="Gérer Associations"
                      onClick={() => openManageAssociationsModal(user.id)}
                    >
                      <FaPlus />
                    </button>
                    <ButtonDelete
                      onClick={() => deleteUser(user.id)}
                      hoverLabel="Supprimer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Cards for mobile, hidden on desktop */}
        <div className="block md:hidden">
          {displayedUsers.map((user) => (
            <div
              key={user.user_id}
              className="flex bg-gray-800 dark:bg-gray-900 rounded-lg shadow-md mb-4"
            >
              {/* Partie gauche */}
              <div className="w-3/10 bg-gray-700 p-4 flex flex-col items-center justify-center gap-2">
                <img
                  className="rounded-full w-10 h-10 object-cover"
                  src={user.photoURL || "/img/user_avatar.jpg"}
                  alt="Avatar"
                />
                <ButtonEdit
                  onClick={() => openEditModal(user.id)}
                  hoverLabel="Éditer"
                  className="text-white"
                />
                <button
                  className="w-8 h-8 bg-yellow-500 text-white rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-yellow-700 flex items-center justify-center"
                  title="Gérer Associations"
                  onClick={() => openManageAssociationsModal(user.id)}
                >
                  <FaPlus />
                </button>
                <ButtonDelete
                  onClick={() => deleteUser(user.id)}
                  hoverLabel="Supprimer"
                  className="text-white"
                />
              </div>

              {/* Partie droite */}
              <div className="w-7/10 p-4">
                <div className="text-white text-lg mb-2">
                  {user.nom} {user.prenom}
                </div>
                <div className="text-gray-400 text-sm">{user.email}</div>
                <div className="text-gray-400 text-sm">
                  Rôle:{" "}
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${getRoleDisplay(user.role_id).className}`}
                  >
                    {getRoleDisplay(user.role_id).name}
                  </span>
                </div>
                <div className="text-gray-400 text-sm">
                  Associations:{" "}
                  {user.Associations && user.Associations.length > 0
                    ? user.Associations.map((association) => (
                        <div key={association.id}>{association.nom_asso}</div>
                      ))
                    : "Aucune association"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <AddUserModal
        isOpen={showAddModal}
        onClose={closeAddUserModal}
        refreshList={refreshUserList}
      />

      {editUserId && (
        <EditUserModal
          isOpen={showEditModal}
          onClose={closeEditUserModal}
          userId={editUserId}
          refreshList={refreshUserList}
        />
      )}

      {editUserId && (
        <ManageAssociationsModal
          isOpen={showManageAssociationsModal}
          onClose={closeManageAssociationsModal}
          userId={editUserId}
          refreshList={refreshUserList}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default ListingUsers;
