import React, { useState, useEffect } from "react";
import useScrollToTop from "../../hooks/useScrollToTop";
import About from "./About";
import Features from "./Features";
import Disciplines from "./Disciplines";
import Diaporama from "./Diaporama";
import FormContact from "./FormContact";
import { useUser } from "../../contexts/UserContext";
import AuthIcons from "../../hooks/AuthIcons";
import Pricing from "./Pricing";
import Footer from "./Footer";
import { saveVisite } from "../../services/visitesServices"; // Assurez-vous que le chemin est correct
import "./Home.css";

function HomePageVitrine() {
  useScrollToTop();

  const [currentImage, setCurrentImage] = useState("/images/fond01.webp");
  const { user } = useUser();

  useEffect(() => {
    const images = ["/images/fond01.webp", "/images/fond02.webp"];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length;
      setCurrentImage(images[currentIndex]);
    }, 7000); // Change image every 7 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10); // Obtenir la date au format AAAA-MM-JJ
    const lastVisitDate = localStorage.getItem("lastVisitDate");

    if (lastVisitDate !== today) {
      // Si la dernière visite enregistrée est différente d'aujourd'hui, on enregistre une nouvelle visite
      saveVisite();
      localStorage.setItem("lastVisitDate", today);
    }
  }, []);

  const scrollToAbout = () => {
    const aboutSection = document.getElementById("about");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDemoClick = () => {
    window.open("https://www.monappliclub-demo.com", "_blank");
  };

  return (
    <div className="flex flex-col min-h-screen">
      {!user && <AuthIcons />}
      <div className="flex-grow">
        <div className="relative h-screen">
          <div
            className="absolute inset-0 transition-opacity duration-1000 ease-in-out bg-cover bg-center"
            style={{
              backgroundImage: `url('${currentImage}')`,
            }}
          ></div>
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-bold mb-4">
              Bienvenue sur
              <br />
              <span className="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Club
              </span>
            </h1>
            <p className="text-lg mb-8">
              La solution idéale pour la gestion de votre club sportif
            </p>
            <div className="flex space-x-4">
              <button
                onClick={scrollToAbout}
                className="px-8 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-full transition relative group"
              >
                <span className="group-hover:opacity-0 transition-opacity duration-300">
                  En savoir plus
                </span>
                <span className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </button>
              <button
                onClick={handleDemoClick}
                className="px-8 py-3 bg-gradient-to-r from-yellow-400 to-orange-600 text-white rounded-full transition relative group"
              >
                <span className="group-hover:opacity-0 transition-opacity duration-300">
                  Tester l'Application
                </span>
                <span className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 font-passionOne text-3xl font-light">
                  Démo
                </span>
              </button>
            </div>
          </div>
          <div id="about">
            <About />
          </div>
          <div id="diaporama">
            <Diaporama />
          </div>
          <div id="features">
            <Features />
          </div>
          <div id="pricing">
            <Pricing />
          </div>
          <div id="disciplines">
            <Disciplines />
          </div>
          <div id="contact">
            <FormContact />
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default HomePageVitrine;
