import React from "react";
import {
  FaRunning,
  FaBicycle,
  FaBasketballBall,
  FaSwimmer,
  FaTableTennis,
  FaFutbol,
  FaFootballBall,
  FaVolleyballBall,
  FaGolfBall,
  FaHockeyPuck,
  FaMountain,
  FaSkiing,
} from "react-icons/fa";
import {
  GiKimono,
  GiHighKick,
  GiFencer,
  GiTennisRacket,
  GiHorseHead,
  GiBoxingGlove,
} from "react-icons/gi";
import {
  MdRowing,
  MdOutlineSportsHandball,
  MdOutlineSportsMartialArts,
  MdSportsKabaddi,
} from "react-icons/md";
import ComponentTitle from "./ComponentTitle";

const icons = {
  FaRunning: FaRunning,
  FaBicycle: FaBicycle,
  FaBasketballBall: FaBasketballBall,
  FaSwimmer: FaSwimmer,
  FaTableTennis: FaTableTennis,
  FaFutbol: FaFutbol,
  FaFootballBall: FaFootballBall,
  FaVolleyballBall: FaVolleyballBall,
  FaGolfBall: FaGolfBall,
  FaHockeyPuck: FaHockeyPuck,
  FaMountain: FaMountain,
  FaSkiing: FaSkiing,
  GiKimono: GiKimono,
  GiHighKick: GiHighKick,
  GiFencer: GiFencer,
  GiTennisRacket: GiTennisRacket,
  GiHorseHead: GiHorseHead,
  GiBoxingGlove: GiBoxingGlove,
  MdRowing: MdRowing,
  MdOutlineSportsHandball: MdOutlineSportsHandball,
  MdOutlineSportsMartialArts: MdOutlineSportsMartialArts,
  MdSportsKabaddi: MdSportsKabaddi,
};

const disciplines = [
  { id: 9, nom: "Judo", icon: "GiKimono", color: "text-orange-500" },
  {
    id: 21,
    nom: "Karaté",
    icon: "MdOutlineSportsMartialArts",
    color: "text-orange-500",
  },
  { id: 5, nom: "Athlétisme", icon: "FaRunning", color: "text-gray-500" },
  { id: 19, nom: "Aviron", icon: "MdRowing", color: "text-gray-500" },
  { id: 11, nom: "Badminton", icon: "GiTennisRacket", color: "text-gray-500" },
  {
    id: 7,
    nom: "Basketball",
    icon: "FaBasketballBall",
    color: "text-gray-500",
  },
  { id: 12, nom: "Boxe", icon: "GiBoxingGlove", color: "text-gray-500" },
  { id: 4, nom: "Cyclisme", icon: "FaBicycle", color: "text-gray-500" },
  { id: 14, nom: "Equitation", icon: "GiHorseHead", color: "text-gray-500" },
  { id: 18, nom: "Escalade", icon: "FaMountain", color: "text-gray-500" },
  { id: 10, nom: "Escrime", icon: "GiFencer", color: "text-gray-500" },
  { id: 1, nom: "Football", icon: "FaFutbol", color: "text-gray-500" },
  { id: 16, nom: "Golf", icon: "FaGolfBall", color: "text-gray-500" },
  {
    id: 8,
    nom: "Handball",
    icon: "MdOutlineSportsHandball",
    color: "text-gray-500",
  },
  {
    id: 15,
    nom: "Hockey sur glace",
    icon: "FaHockeyPuck",
    color: "text-gray-500",
  },
  { id: 22, nom: "Ju-Jitsu", icon: "MdSportsKabaddi", color: "text-gray-500" },
  { id: 6, nom: "Natation", icon: "FaSwimmer", color: "text-gray-500" },
  { id: 2, nom: "Rugby", icon: "FaFootballBall", color: "text-gray-500" },
  { id: 20, nom: "Ski", icon: "FaSkiing", color: "text-gray-500" },
  { id: 17, nom: "Taekwondo", icon: "GiHighKick", color: "text-gray-500" },
  {
    id: 3,
    nom: "Tennis de Table",
    icon: "FaTableTennis",
    color: "text-gray-500",
  },
  {
    id: 13,
    nom: "Volley-ball",
    icon: "FaVolleyballBall",
    color: "text-gray-500",
  },
];

const handleDemoClick = () => {
  window.open("https://www.monappliclub-demo.com", "_blank");
};

const Disciplines = () => {
  return (
    <section id="disciplines" className="py-8 bg-base-200 text-gray-800">
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Les" highlight=" Disciplines" />
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Découvrez les disciplines actuellement disponibles sur MonAppliClub.
          </p>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4">
          {disciplines.map((discipline, idx) => {
            const IconComponent = icons[discipline.icon];
            return (
              <div
                key={idx}
                className="flex flex-col items-center justify-center p-3 border border-gray-200 rounded-lg shadow-lg transition transform hover:scale-105 bg-white cursor-pointer"
              >
                <IconComponent
                  className={`text-3xl mb-2 ${discipline.color}`}
                />
                <h3
                  className={`text-sm font-semibold ${discipline.color} text-center`}
                >
                  {discipline.nom}
                </h3>
              </div>
            );
          })}
        </div>
        <div className="mt-12 text-center">
          <p className="text-lg font-semibold text-gray-700">
            L'application est 100% adaptable aux spécificités de votre
            discipline. Merci de préciser vos demandes dans le formulaire de
            contact.
          </p>
        </div>
        <button
          onClick={handleDemoClick}
          className="flex mx-auto text-center px-8 py-3 bg-gradient-to-r from-yellow-400 to-orange-600 text-white rounded-full transition relative group mt-8"
        >
          <span className="group-hover:opacity-0 transition-opacity duration-300">
            Tester l'Application
          </span>
          <span className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 font-passionOne text-3xl font-light">
            Démo
          </span>
        </button>
      </div>
    </section>
  );
};

export default Disciplines;
