import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ComponentTitle from "./ComponentTitle";

const Diaporama = () => {
  return (
    <div>
      <div className="py-16 bg-base-100 text-gray-800">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Quelques" highlight=" Pages" />
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Voici un diaporama de quelques pages de l'application.</p>
        </div>
        </div>
    <Carousel
      autoPlay
      infiniteLoop
      useKeyboardArrows
      dynamicHeight
      showThumbs={false}
      autoplaySpeed={5000}
    >
      <div>
        <img src="/images/diapo/slider_001.webp" alt="Diapo 1" />
      </div>
      <div>
        <img src="/images/diapo/slider_002.webp" alt="Diapo 2" />
      </div>
      <div>
        <img src="/images/diapo/slider_003.webp" alt="Diapo 3" />
      </div>
      <div>
        <img src="/images/diapo/slider_004.webp" alt="Diapo 4" />
      </div>
      <div>
        <img src="/images/diapo/slider_005.webp" alt="Diapo 5" />
      </div>
      <div>
        <img src="/images/diapo/slider_006.webp" alt="Diapo 6" />
      </div>
      <div>
        <img src="/images/diapo/slider_007.webp" alt="Diapo 7" />
      </div>
      <div>
        <img src="/images/diapo/slider_008.webp" alt="Diapo 8" />
      </div>
      <div>
        <img src="/images/diapo/slider_009.webp" alt="Diapo 9" />
      </div>
      <div>
        <img src="/images/diapo/slider_010.webp" alt="Diapo 10" />
      </div>
      <div>
        <img src="/images/diapo/slider_011.webp" alt="Diapo 11" />
      </div>
      <div>
        <img src="/images/diapo/slider_012.webp" alt="Diapo 12" />
      </div>
      <div>
        <img src="/images/diapo/slider_013.webp" alt="Diapo 13" />
      </div>
      <div>
        <img src="/images/diapo/slider_014.webp" alt="Diapo 14" />
      </div>
      <div>
        <img src="/images/diapo/slider_015.webp" alt="Diapo 15" />
      </div>
      <div>
        <img src="/images/diapo/slider_016.webp" alt="Diapo 16" />
      </div>
      <div>
        <img src="/images/diapo/slider_017.webp" alt="Diapo 17" />
      </div>
      <div>
        <img src="/images/diapo/slider_018.webp" alt="Diapo 18" />
      </div>
    </Carousel>
    </div>
  );
};

export default Diaporama;
